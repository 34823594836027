






















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      item: null,
      currentTab: "",
      tabs: {
        warehouses: {
          icon: "mdi-warehouse",
          label: "Kho",
          condition() {
            return userManager.checkRole(["kt", "vdl", "vd"]);
          },
        },
        product_groups_advanced: {
          icon: "fas fa-box-open",
          label: "Sản phẩm đang bán",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "vdl", "vd", "ksnb", "ksnbl", "content", "design"]);
          },
        },
        products: {
          icon: "fas fa-cube",
          label: "Sản phẩm",
          condition() {
            return userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "vdl", "vd"]);
          },
        },
        product_prices: {
          icon: "mdi-currency-usd",
          label: "Giá bán sản phẩm",
          condition() {
            return userManager.checkRole(["kt", "tp", "mkt"]) || (userManager.checkRole(["vdl"]) && userManager.checkRole(["qlk"]));
          },
        },
        purchase_commands: {
          icon: "mdi-basket",
          label: "Mua hàng",
          condition() {
            return userManager.checkRole(["kt"]) || (userManager.checkRole(["vdl"]) && userManager.checkRole(["qlk"]));
          },
        },
        warehouse_imports: {
          icon: "fas fa-file-import",
          label: "Nhập kho",
          condition() {
            return userManager.checkRole(["kt"]) || (userManager.checkRole(["vdl"]) && userManager.checkRole(["qlk"]));
          },
        },
        warehouse_returns: {
          icon: "fas fa-file-import",
          label: "Import - Return",
          condition() {
            return userManager.checkRole(["kt", "qlk", "vdl", "vd"]);
          },
        },
        warehouse_returns_scan: {
          icon: "barcode-scan",
          label: "Import - Return Scan",
          condition() {
            return userManager.checkRole(["kt", "qlk", "vdl", "vd"]);
          },
        },
        warehouse_returns_scan_history: {
          icon: "mdi-barcode",
          label: "Import - Return Scan History",
          condition() {
            return userManager.checkRole(["kt", "qlk", "vdl", "vd"]);
          },
        },
        warehouse_reset_commands: {
          icon: "mdi-store-cog",
          label: "Phiếu kiểm kê kho",
          condition() {
            return userManager.checkRole(["kt", "vdl", "vd"]);
          },
        },
      },
    };
  },
  async created() {
    this.item = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
  },
});
