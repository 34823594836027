


















































































































import Vue from "vue";
import moment from "moment";
import productComboProductItemRolesEn from "@/constants/productComboProductItemRolesEn";

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      moment,
      productComboProductItemRolesEn,
    };
  },
});
