

























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import moment from "moment";
import makeSelectOptions from "@common/util/makeSelectOptions";
import wholesaleProductPriceStatuses from "@/constants/wholesaleProductPriceStatuses";

export default Vue.extend({
  data() {
    return {
      userManager,
      tableOptions: null,
      item: null,
    };
  },
  methods: {
    makeTableOptions(item) {
      const item2 = item;

      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Giá bán sỉ",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              startTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày bắt đầu (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày bắt đầu (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              endTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kết thúc (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kết thúc (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              productId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            localIndex: {
              text: "STT",
              options: {
                index: true,
              },
            },
            product: {
              text: "Sản phẩm",
              options: {
                subProp: "product.sku",
              },
            },
            status: {
              text: "Trạng thái",
              options: {
                label: (item) => wholesaleProductPriceStatuses[item.status],
              },
            },
            description: {
              text: "Mô tả",
              hidden: true,
            },
            startTime: {
              text: "Ngày bắt đầu",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            endTime: {
              text: "Ngày kết thúc",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            price: {
              text: `Đơn giá trước discount (${item.currency})`,
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            items: {
              text: "Chi tiết",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            insert: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "800px",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "800px",
                    },
                    ext: {
                      subTitleMaker({ product, startDate, endDate }) {
                        return `${product.sku} - ${moment(startDate).format("YYYY-MM-DD")} - ${moment(endDate).format("YYYY-MM-DD")}`;
                      },
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: item._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("wholesaleProductPrices", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              item.marketId = item2._id;
              return await coreApiClient.call("wholesaleProductPrices", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "wholesaleProductPrices",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("wholesaleProductPrices", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              productId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  "item-disabled": "_",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: item.countryId,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(wholesaleProductPriceStatuses),
                  required: true,
                },
                ext: {
                  defaultValue: "db",
                },
              },
              startTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian bắt đầu",
                  required: true,
                },
              },
              endTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian kết thúc",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              price: {
                type: "number",
                attrs: {
                  label: `Đơn giá trước discount (${item.currency})`,
                  required: true,
                },
              },
              items: {
                type: "XArrayInput",
                attrs: {
                  label: "",
                  xOptions: {
                    content: {
                      itemLabel: (item) => `${item.from || ""} - ${item.to || ""}: ${item.discountPercentage || 0}%`,
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                      itemProperties: {
                        from: {
                          type: "integer",
                          attrs: {
                            label: "From",
                            required: true,
                          },
                        },
                        to: {
                          type: "integer",
                          attrs: {
                            label: "To",
                          },
                        },
                        discountPercentage: {
                          type: "number",
                          attrs: {
                            label: "Discount (%)",
                            required: true,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              content: {
                fieldNames: ["productId", "status", "startTime", "endTime", "price", "items", "description"],
              },
            },
            editForm: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              content: {
                fieldNames: ["productId", "status", "startTime", "endTime", "price", "items", "description"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {},
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.item);
  },
});
