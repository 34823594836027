















import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  components: { StatisticsPage },
  data() {
    return {
      userManager,
      orderFields: [
        { value: "revenue", text: "DSDK MKT" },
        { value: "realRevenue", text: "DSDK MKT thực" },
        { value: "returnPercentage", text: "% Return" },
        { value: "mktCostPerRevenue", text: "& CPQC / DSDK MKT" },
        { value: "count", text: "Tổng số đơn" },
        {
          value: "chatPageCountPerCount",
          text: "% Đơn Chat Page / Tổng số đơn",
        },
        { value: "dailyBonus", text: "Tổng thưởng ngày" },
        { value: "qualityRevenue", text: "Doanh số hiệu quả" },
      ],
      extraFiltersOptions: {
        count: {
          label: "Tổng số đơn",
          clearable: true,
          items: [
            {
              value: "",
              text: "Tất cả",
            },
            {
              value: "0_50",
              text: "0-50",
            },
            {
              value: "50_200",
              text: "50-200",
            },
            {
              value: "200_",
              text: ">200",
            },
          ],
        },
      },
      fields: {
        qualityRevenue: "Doanh số hiệu quả",
        revenue: "DSDK MKT",
        realRevenue: "DSDK MKT thực",
        mktCost: "CPQC",
        mktCostPerRevenue: "CPQC/DSDK MKT",
        dailyBonus: "Tổng thưởng ngày",
        count: "Tổng số đơn",
        adsAgencyAccountCount: "Số lượng TKQC",
        adsAgencyAccounts: "TKQC",
        chatPageCount: "Số đơn hàng chatpage",
        chatPageCountPerTotalCount: "%đơn chatpage/Tổng số đơn",
        chatPageRevenue: "DSDK MKT đơn chatpage",
        chatPageRevenuePerTotalRevenue: "%DSDK MKT đơn chatpage/Tổng DSDK MKT",
        income: "Income thực",
        realCommissionFee: "Commission Fee thực",
        commissionFee: "Commission Fee dự kiến",
        successChatPageRevenue: "DSDK MKT đơn chatpage giao hàng thành công",
        returnChatPageRevenue: "DSDK MKT đơn chatpage return",
        inTransitChatPageRevenue: "DSDK MKT đơn chatpage đang giao",
        successChatPageRevenuePerTotalChatPageRevenue: "%DSDK MKT đơn chatpage giao hàng thành công / DSDK MKT đơn chatpage",
        returnCountPerReturnAndSuccessCount: "%Return/(Return+Success)",
        returnCount: "Return (đơn)",
        successCount: "Success (đơn)",
        inTransitCount: "Đang giao (đơn)",
        successRevenue: "Success (VNĐ)",
      },
    };
  },
});
