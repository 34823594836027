























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import moment from "moment";
import productComboDiscountProductConditions from "@/constants/productComboDiscountProductConditions";
import makeSelectOptions from "@common/util/makeSelectOptions";

export default Vue.extend({
  data() {
    return {
      userManager,
      tableOptions: null,
      item: null,
    };
  },
  methods: {
    makeTableOptions(item) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Chương trình khuyến mãi combo",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              startTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày bắt đầu (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày bắt đầu (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              endTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kết thúc (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kết thúc (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              productGroupIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            name: {
              text: "Mô tả",
            },
            description: {
              text: "Mô tả",
              hidden: true,
            },
            startTime: {
              text: "Ngày bắt đầu",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            endTime: {
              text: "Ngày kết thúc",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            productsCondition: {
              text: "Điều kiện SP áp dụng",
              options: {
                label(value) {
                  return productComboDiscountProductConditions[value];
                },
              },
            },
            priceGreaterThan: {
              text: "Giá bán lớn hơn",
            },
            priceLessThan: {
              text: "Giá bán nhỏ hơn",
            },
            productGroups: {
              text: "Nhóm sản phẩm",
              options: {
                labels(items) {
                  return items && items.map((item) => item.sku);
                },
              },
            },
            items: {
              text: "Chi tiết",
            },
            action: {},
          },
          topActionButtons: {
            insert: {
              target: {
                dialog: {
                  attrs: {
                    width: "800px",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "800px",
                    },
                    ext: {
                      subTitleMaker({ startDate, endDate }) {
                        return `${moment(startDate).format("YYYY-MM-DD")} - ${moment(endDate).format("YYYY-MM-DD")}`;
                      },
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: item.marketId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("productComboDiscounts", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              item.marketId = item._id;
              return await coreApiClient.call("productComboDiscounts", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "productComboDiscounts",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productComboDiscounts", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              startTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian bắt đầu",
                  required: true,
                },
              },
              endTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Thời gian kết thúc",
                  required: true,
                },
              },
              productsCondition: {
                type: "select",
                attrs: {
                  label: "Điều kiện SP áp dụng",
                  items: makeSelectOptions(productComboDiscountProductConditions),
                  required: true,
                },
                ext: {
                  defaultValue: "tc",
                },
              },
              priceGreaterThan: {
                type: "number",
                attrs: {
                  label: "Giá bán lớn hơn",
                },
              },
              priceLessThan: {
                type: "number",
                attrs: {
                  label: "Giá bán nhỏ hơn",
                },
              },
              productGroupIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              items: {
                type: "XArrayInput",
                attrs: {
                  label: "",
                  xOptions: {
                    content: {
                      itemLabel: (item) => `Combo ${item.count || ""} - Giảm giá ${Vue.filter("currency")(item.value) || ""}`,
                      itemProperties: {
                        count: {
                          type: "integer",
                          attrs: {
                            label: "Combo",
                            required: true,
                          },
                        },
                        value: {
                          type: "number",
                          attrs: {
                            label: "Giảm giá",
                            required: true,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: ["name", "description", "startTime", "endTime", "productsCondition", "priceGreaterThan", "priceLessThan", "productGroupIds", "items"],
              },
            },
            editForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: ["name", "description", "startTime", "endTime", "productsCondition", "priceGreaterThan", "priceLessThan", "productGroupIds", "items"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.item);
  },
});
