



















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      item: null,
      currentTab: "",
      permitted: false,
      tabs: {
        dummy: {
          label: "Tổng thống kê quốc gia",
        },
        index: {
          label: "Tổng quan",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        product_groups: {
          label: "Nhóm sản phẩm",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
        in_transit_products: {
          label: "Hàng đi đường",
          condition() {
            return userManager.checkRole(["kt"]) || (userManager.checkRole(["qlk"]) && userManager.checkRole(["vdl"]));
          },
        },
        in_warehouse_products: {
          label: "Hàng tồn kho",
          condition() {
            return userManager.checkRole(["kt", "qlk"]);
          },
        },
        purchase_commands: {
          label: "Mua hàng",
          condition() {
            return userManager.checkRole(["kt"]);
          },
        },
      },
    };
  },
  async created() {
    this.checkPermitted();
  },
  methods: {
    checkPermitted() {
      this.permitted = userManager.checkRole(["kt", "vd", "vdl", "qlk"]);
    },
  },
});
