var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.label)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(!_vm.options.content.addItemBtn.ext || !_vm.options.content.addItemBtn.ext.hidden)?_c('x-btn',{attrs:{"x-options":_vm.options.content.addItemBtn,"x-context":_vm.self}}):_vm._e()],1)],1),_c('v-expansion-panels',{attrs:{"popout":"","multiple":""},model:{value:(_vm.expandedIndexes),callback:function ($$v) {_vm.expandedIndexes=$$v},expression:"expandedIndexes"}},[_vm._l((_vm.items),function(item,i){return [(
          _vm.options.content.removeMethod === 'setDeleted' || !_vm.removedItems[i]
        )?_c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!item.deleted && !_vm.options.content.deleteDisabled)?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeItem(i)}}},[_vm._v(" fas fa-trash ")]):_vm._e()]},proxy:true}],null,true)},[_c('div',{staticClass:"font-weight-bold",class:{
              'red--text text-decoration-line-through	': item.deleted,
            },domProps:{"innerHTML":_vm._s(
              (_vm.options.content.itemLabel &&
                _vm.options.content.itemLabel(item, _vm.realIndex(i), _vm.self)) ||
              ("Item " + (_vm.realIndex(i) + 1))
            )}})]),_c('v-expansion-panel-content',[_c('x-form',{attrs:{"x-options":_vm.itemFormOptions[i],"x-data":_vm.itemFormDatas[i],"x-container":_vm.itemFormContainers[i],"x-model":_vm.itemValids[i],"x-context":_vm.self},on:{"changed":function($event){return _vm.onItemUpdated(i, $event)}}})],1)],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }